import axios from "axios";

const UserServices = () => {
  const login = async (data) => {
    return await axios
      .post(`${process.env.VUE_APP_ROOT_API}/users/login`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const userMe = async () => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/users/me`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const userAll = async (type) => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/users/?type=${type}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const saveUser = async (data) => {
    return await axios
      .post(`${process.env.VUE_APP_ROOT_API}/users`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const updateUser = async (id, data) => {
    return await axios
      .put(`${process.env.VUE_APP_ROOT_API}/users/${id}`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const recoveryPasswordUser = async (email) => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/users/recovery-password/${email}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const deleteUser = async (id) => {
    return await axios
      .delete(`${process.env.VUE_APP_ROOT_API}/users/${id}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  return {
    login,
    userMe,
    userAll,
    saveUser,
    updateUser,
    recoveryPasswordUser,
    deleteUser,
  };
};

export default UserServices;
