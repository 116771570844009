<template>
  <v-dialog
    v-model="viewModal"
    max-width="620px"
    persistent
    scrollable
    content-class="admin-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="second"
        class="mb-1 white--text"
        v-bind="attrs"
        v-on="on"
        @click="btnDialog()"
      >
        Registrar
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="card-title">
        <span class="headline">{{ edit ? "Editar" : "Registrar" }}</span>

        <v-spacer></v-spacer>

        <CustomButtonAction
          :btnApply="true"
          :btnCancel="true"
          :loaderApply="loading"
          colorBtnApply="white"
          colorBtnCancel="white"
          @onChangeApply="apply()"
          @onChangeCancel="cancel()"
        />
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Cédula"
                  v-model="item.dni"
                  :autocomplete="'off'"
                  :rules="dniRules"
                  :maxlength="10"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Nombre"
                  v-model="item.name"
                  :autocomplete="'off'"
                  :rules="requiredRules"
                  :maxlength="100"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="Apellido"
                  v-model="item.lastName"
                  :autocomplete="'off'"
                  :rules="requiredRules"
                  :maxlength="100"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Correo electrónico"
                  v-model="item.email"
                  :autocomplete="'off'"
                  :rules="emailRules"
                  :maxlength="150"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="Teléfono"
                  v-model="item.phone"
                  :autocomplete="'off'"
                  :rules="numberRules"
                  :maxlength="20"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import useValidate from "../../../../shared/validate/useValidate";
import UserServices from "../../../../core/services/user-services";

export default {
  name: "EditSeller",
  data: () => ({
    viewModal: false,
    valid: false,
    loading: false,
    item: {},
    requiredRules: [useValidate["required"]],
    emailRules: [useValidate["required"], useValidate["email"]],
    dniRules: [useValidate["required"], useValidate["dni"]],
    numberRules: [useValidate["phone"]],
  }),
  components: {
    CustomButtonAction,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    data: {},
  },
  watch: {
    dialog: function (value) {
      this.item = {};
      this.viewModal = value;
    },
    edit: function (value) {
      if (value) {
        this.item = Object.assign({}, this.data);
      }
    },
  },
  methods: {
    btnDialog() {
      this.viewModal = true;
    },
    apply() {
      const validate = this.$refs.form.validate();
      if (validate) {
        if (this.edit) {
          this.btnEdit();
        } else {
          this.btnSave();
        }
      }
    },
    cancel() {
      this.viewModal = false;
      this.$emit("update:dialog", false);
      this.$emit("update:edit", false);
      setTimeout(() => {
        this.$refs.form.reset();
      }, 500);
    },
    btnSave() {
      this.loading = true;
      const { saveUser } = UserServices();
      this.item.typeUser = {
        id: 4,
        name: "Vendedor",
      };
      this.item.connect = `seller-${this.item.dni}`;
      saveUser(this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          this.$toast.success("El vendedor se ha registrado de forma exitosa");
          this.$emit("onChange", response.data);
          this.cancel();
        }
        this.loading = false;
      });
    },
    btnEdit() {
      this.loading = true;
      const { updateUser } = UserServices();
      this.item.typeUser = {
        id: 4,
        name: "Vendedor",
      };
      this.item.connect = `seller-${this.item.dni}`;
      updateUser(this.item.id, this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          this.$toast.success("El vendedor se ha actualizado de forma exitosa");
          this.$emit("onChange", response.data);
          this.cancel();
        }
        this.loading = false;
      });
    },
  },
};
</script>
