import { db } from "./db.js";

const SellerStorage = () => {
  const sellerAdd = async (data) => {
    await db.seller.put(data);
  };

  const sellerAll = async () => {
    return await db.seller.orderBy("createdAt").reverse().toArray();
  };

  const sellerDeleteById = async (id) => {
    await db.seller.delete(id);
  };

  const sellerClear = async () => {
    await db.seller.clear();
  };

  return {
    sellerAdd,
    sellerAll,
    sellerDeleteById,
    sellerClear,
  };
};

export default SellerStorage;
